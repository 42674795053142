










import Vue from 'vue'
import store from '../../store/session';

export default Vue.extend({
    props: {
        isCartOpen: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        count() {
            return store.state.cart.orders.length;
        }
    },
    methods: {
        handleClickCartIcon() {
            if (this.isCartOpen) {
                this.$emit('closeCart');
            } else {
                this.$emit('openCart');
            }
        },
    }
});
